.title-sm {
  display: flex;
  flex-direction: row;
  gap: 16px;
  cursor: pointer;
}

.title-tag {
  border-radius: 4px;
  width: 16px;
  height: 32px;
}

.title-sm-text {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}