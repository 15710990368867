.app-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'sidebar header'
    'sidebar main';
  grid-area: main;
}

.main-content {
  --main-height: calc(100vh);
  height: var(--main-height);
  background: #f1f4f9;
  grid-area: main;
  overflow: scroll;
}

.main-content::-webkit-scrollbar {
  display: none;
}

.label {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #33383f;

  &__gray {
    color: #768c9c;
  }
}

.modal-text {
  color: #6f767e !important;
  font-weight: 400;
  font-size: 15px;
}

.submodal-body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.submodal-body::-webkit-scrollbar {
  display: none;
}
