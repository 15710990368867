.input-box {
  height: 48px !important;
  font-family: 'Nunito' !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: -0.01em !important;
  color: #33383F;

  &_green {
    font-size: 16px !important;
    padding: 16px 20px;
  }
  
  &__green-label {
    padding-top: 26px;
  }
}

.green-label {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #096B72;
}