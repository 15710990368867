.tag-input-box {
  border-radius: 8px !important;
  // border: none !important;
  box-shadow: none !important;
  font-weight: 400;
  flex: 1;
  padding-left:4px;
  padding-right:10px;

}
.tag-input-box:focus {
  border: 1px solid #069d47 !important;
}
.tag-input-box > div > div > div > input {
  font-family: 'Nunito';
  border: none !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #101828;
}
.css-1defwtr[aria-activedescendant] {
  border: 1px solid #667085 !important;
}
.tag-input-box > div > div > div > input::placeholder {
  color: #667085;
}
.tag-input-box > div > div > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tag-input-box > div > div > div > strong {
  margin-left: 8px;
  margin-right: 0;
  height: 23px;
  margin-top:5px;
  margin-bottom:5px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: #2B292D;
  background-color:#EDEFF5;
  border-radius: 8px;
}

.tag-input-box > div > div > div > strong > svg {
  fill:#2B292D;
  height: 12px;
  width: 12px;
  margin-top:1px;
  margin-left: 6px;
}


.tag-count {
  font-family: 'Nunito';
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #33383F;
  display: flex;
  justify-content: center;
  align-items: center;

  &__max {
    color: #9A9FA5;
  }
}