.default-button {
  font-family: 'Nunito' !important;

  &:not([disabled])[data-active] {
    background: #efefef !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}
