.meeting-layout {
  height: 100vh;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'content';

  .video-content {
    grid-area: content;
  }
}

.meeting-content {
  position: relative;
  grid-area: content;
  height: 100%;
  display: flex;
  flex-direction: column;

  .videos {
    flex: 1;
    background-color: #096B72;
    color: white;
  }

  .controls {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 768px) {
    .controls {
      position: static;
      transform: unset;
    }
  }
}