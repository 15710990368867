.react-datepicker {
  overflow: hidden;
  border: 1px solid #ffff;
  border-radius: 20px;

  button {
    border-color: transparent !important;
  }
}

.react-datepicker__month {
  margin: 0px 35px 20px 35px;
}

.react-datepicker__current-month {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #3C3F4D;
  height: 56px;
}

.react-datepicker__day-names, .react-datepicker__month {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #768C9C;
}

.react-datepicker__day {
  width: 32px;
  height: 32px;
  text-align: center;
  vertical-align: middle;
  line-height: 32px;       /* The same as your div height */
}

.react-datepicker__day--selected {
  background-color: #096B72;
  border-radius: 50%;
}

.react-datepicker__day:hover {
  border-radius: 50%;
  background-color: #ffffff;
}

.react-datepicker__header {
  background: transparent;
  border-bottom: none;
  padding: 0;
  padding-top: 18px;
}

.react-datepicker__day-name {
  height: 32px;
  width: auto;
}

// .datepicker-white {

// }

// .datepicker-gray {
//   background: #F0F0F0;
// }