input {
  color: #101828;
  border-radius: 8px;
}
.form-pat input {
  border: 1px solid #d0d5dd !important;
}
.form-pat input:focus {
  border: 1px solid #667085 !important;
}
input::placeholder {
  color: #667085;
}
.form-pat select {
  border: 1px solid #d0d5dd !important;
}
.form-pat select:focus {
  border: 1px solid #667085 !important;
}
.form-pat select:focus-visible {
  box-shadow: none !important;
}
.main-section-patient{
  border-left: 1px solid #edeff5;
  border-right: 1px solid #edeff5;
  box-shadow: 0px 1px 3px 0px rgba(43, 41, 45, 0.06);
  border-radius: 0 0 12px 12px;
}

.link-psw{
  color: rgb(9, 107, 114);
  font-weight: 700;
cursor: pointer;
}

.link-psw span:hover{
  text-decoration: underline;
  text-underline-offset: 8px;
}