.patient-container {
  height: 100%;
  margin-right: 32px;
  margin-left: 32px;
  margin-top: 24px;
}

.patient-header {
  border: 1px solid #edeff5;
  border-bottom: none;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(43, 41, 45, 0.06);
  border-radius: 12px 12px 0 0;
  // height: 225px;
  padding: 24px;
  padding-left: 32px;
  padding-right: 32px;

  &__info {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.patient-name {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 48px;
  letter-spacing: -0.03em;
  color: #272b30;
}

.nav-tab-settings {
  font-family: "Nunito" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 24px !important;
  letter-spacing: -0.01em !important;
  border-radius: 4px 4px 0px 0px !important;
  margin: 0 !important ;
  padding: 12px !important;
}

.upload-photo-icon {
  margin-left: 50px;
  margin-top: -36px;
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 100px;
  border: 1.5px solid #fff;
  background: #fff;
  box-shadow: 0px 1px 24px 0px rgba(9, 107, 114, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.settings-title {
  margin-top: 40px;
  margin-left: 22px;
  margin-bottom: 32px;
  color: rgb(26, 32, 44);
  font-weight: bold;
  font-size: 26px;
  display: flex;
}
