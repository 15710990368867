.sidebar {
  display: flex;
  flex-direction: column;
  flex: 1;
  grid-area: sidebar;
  width: 280px;
  background: #fcfcfc;
  padding: 24px;
}

.tab-text {
  font-weight: 600;
  fontsize: 16px;
  font-family: "Nunito";
  text-decoration: none;
  margin-top: 2px;
}

.menu-title {
  font-size: 14px;
  font-weight: 700;
  font-family: "Nunito";
  margin-bottom: 8px;
}
