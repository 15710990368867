#chakra-modal-mymodal {
  min-width: 640px !important;
  margin-top: 100px;
}

input {
  color: #101828;
  border-radius: 8px;
}
.form-pat input {
  border: 1px solid #d0d5dd !important;
}
.form-pat input:focus {
  border: 1px solid #667085 !important;
}
input::placeholder {
  color: #667085;
}
.modal-title {
  color: #1a1d1f;
  font-weight: 600;
  font-size: 20px;
}
.photo-box {
  border-radius: 10px;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.textarea-label {
  font-family: Nunito;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #33383f;
}
.modal-footer {
  border-top: 1px solid #edeff5;
  justify-content: space-between !important;
  height: 93px;
  padding: 24px;
}

.textarea-base {
  border: 2px solid rgba(154, 159, 165, 0.25);
  border-radius: 12px;
  height: 160px;

  &__gray {
    background: #f4f4f4;
  }
}

.textarea-base ::-webkit-resizer {
  border-width: 8px;
  border-style: solid;
  border: 2px solid rgba(154, 159, 165, 0.25);
}
.button-save {
  font-weight: 700;
  line-height: 24px;
}
