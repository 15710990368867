.main-container {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 32px;
  margin-bottom: 32px;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 16px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 12px;
  box-shadow: 0px 0.781928px 18.7663px rgba(9, 107, 114, 0.08);
}
