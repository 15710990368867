@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700&family=Nunito:wght@400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Nunito" !important;
  overflow: hidden;
}

input {
  // border-radius: 12px !important;
  // border: 0 !important;
  caret-color: #66e3c3;
}

input:focus {
  box-shadow: none !important;
}

// Default calendar button class
.fc-button-primary {
  height: 40px !important;
  border: 2px solid #efefef !important;
  font-weight: 700 !important;
  color: #1a1d1f !important;

  &:focus {
    box-shadow: none !important;
  }
}

// button {
//   height: 40px !important;
//   border: 2px solid #EFEFEF !important;
//   font-weight: 700 !important;
//   color: #1A1D1F !important;
// }

// button:not([disabled])[data-active] {
//   background: #EFEFEF !important;
// }

// button:focus {
//   box-shadow: none !important;
// }

.table {
  border: 0 !important;
}

.table-header {
  background: #fcfcfc !important;
  text-transform: none !important;
  font-weight: 600 !important;
  font-size: 13px !important;
}

.gray-input {
  background: #f4f4f4 !important;
}

.css-1dccw9w > div:first-child {
  padding: 24px;
}

.css-1dccw9w > div:last-child {
  padding: 0px 24px 24px 24px;
}
.sd-navigation__complete-btn,
.sd-navigation__next-btn {
  background: var(--chakra-colors-teal-500) !important;
  background-color: var(--chakra-colors-brand-500) !important;
  border-radius: 8px;
  height: 44px;
  line-height: 1.2;
  vertical-align: middle;
  outline-offset: 2px;
  padding: 10px 32px !important;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  color: white;
  font-family: 'Nunito';
  font-size: 16px;
}
.sd-navigation__complete-btn:hover,
.sd-navigation__next-btn:hover {
  background: var(--chakra-colors-teal-600) !important;
  color: white;
}
