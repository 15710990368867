.info-row {
  width: 100%;
  border-bottom: 1px solid rgba(17, 19, 21, 0.13);
  padding-bottom: 16px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.info-text {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;

  &__label {
    color: #6F767E;
  }

  &__placeholder {
    color: rgba(111, 118, 126, 0.5);
  }
}