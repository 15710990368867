.clinic-container {
  height: 100%;
  margin-right: 91px;
  margin-left: 91px;
  margin-top: 40px;
}

.clinic-header {
  background: #fcfcfc;
  border-radius: 8px;
  height: 225px;
  padding: 24px;

  &__info {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.clinic-name {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.03em;
  color: #1a1d1f;
}

.nav-tab {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.01em;
}

.clinic-edit-body {
  background: #F4F4F4;
  border-radius: 8px;
  width: 672px;
  padding: 24px;
}