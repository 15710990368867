.toggle-input {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #33383F;
}

.switch-input_on > div {
  background-color: #66E3C3 !important;
}

.switch-input_off > div {
  background-color: #d8dae5 !important;
}