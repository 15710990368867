.controls-menu {
  width: 100%;
  position: static;
  background-color: #096B72 !important;
  box-shadow: none !important;
}

.base-control-button {
  width: 48px;
  height: 48px;
  background: #768C9C;
  box-shadow: 0px 0.781928px 18.7663px rgba(9, 107, 114, 0.08);
  border-radius: 50%;
  grid-template-rows: 2.8rem !important;
  grid-template-columns: 3rem minmax(0.5rem,auto) !important;
}

.end-call-button {
  background: #E83800;
  box-shadow: 0px 0.781928px 18.7663px rgba(9, 107, 114, 0.08);
  border-radius: 25.6195px;
  width: 72px;
  height: 48px;
  grid-template-rows: 3.5rem !important;
  grid-template-columns: 4rem minmax(0.5rem,auto) !important;
}

.base-control-button > span > button {
  &:hover, 
  &:focus,
  &:active {
    background-color: #768C9C !important;
    border: none;
    box-shadow: none;
  }

  border: none;
}

.end-call-button > span > button {
  &:hover, 
  &:focus,
  &:active {
    background-color: #E83800 !important;
    border: none;
    box-shadow: none;
  }

  border: none;
}

.share-button-padding > span > button {
  padding-top: 4px;
}